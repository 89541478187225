.bordered-table {
    border: 1px solid var(--lighter-text);
    border-radius: 10px;
    font-size: 12px;
    letter-spacing: 0.6px;

}

.bordered-table>thead>tr,
.dialog-table>thead>tr {
    background: linear-gradient(to top,
            rgba(108, 156, 122, 0.9),
            rgba(36, 89, 54, 0.9),
            rgba(36, 89, 54, 0.9),
            rgba(36, 89, 54, 0.9),
            rgba(36, 89, 54, 0.9));

}

.bordered-table>thead>tr>th,
.dialog-table>thead>tr>th {
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}

.bordered-table>tbody>tr>td,
.bordered-table>tbody>tr>td>span {
    font-family: Tahoma, sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #222;
}

.bordered-table>tbody>tr>td>span {
    color: #fff;
}

.bordered-table>tbody>tr:nth-child(even),
.dialog-table>tbody>tr:nth-child(even) {
    background: #f9f9f9;
}

.bordered-table>tbody>tr:hover:nth-child(even),
.dialog-table>tbody>tr:hover:nth-child(even) {
    /* background: #ceccccaa; */
    background: var(--transparent-green);
}

.dialog-table>tbody>tr>td:first-child {
    color: var(--faded-main-green);
    font-weight: 600;
}

.bordered-table>tbody>tr>td>button,
tbody>tr>td>button {
    padding: 2px 6px;
    font-size: 0.9em;
}

.table-container {
    display: block;
    max-height: 60vh;
}


/* References Summary Table */
.outgoing-ref-table {
    display: table;
    width: 600px;
    margin: 30px auto 20px;
}

.outgoing-ref-table caption {
    background:var(--faded-main-green);
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    color: #fff;
}

.outgoing-ref-table tr:hover{
    color: #000;
    background: rgba(36, 89, 54, 0.295)
}

.outgoing-ref-table .rowBold {
    font-weight: bold;
    color: var(--faded-main-green);
}


/* Pagination */
.pagination {
    display: flex;
    background: #fff;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

}

.pagination button {
    display: block;
    padding: 7px 14px;
    text-decoration: none;
    transition: background-color 0.3s;
    color: var(--normal-text);
    border: none;
    font-weight: 600;
    border-top: 1px solid var(--lighter-text);
    border-bottom: 1px solid var(--lighter-text);
    cursor: pointer;

}

.pagination button:first-child {
    border-left: 1px solid var(--lighter-text);
}

.pagination button:last-child {
    border-right: 1px solid var(--lighter-text);

}

.pagination button.active {
    background: var(--faded-main-green);
    color: #fff;
    cursor: default;
}

.pagination button:hover:not(.pagination button.active) {
    background: var(--golden-rod);
    color: #fff;
}


/* 404 page */
.page-background {
    display: flex;
    position: relative;
    background: rgba(185, 241, 203, 0.9);
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';

}

.page-background .error-page {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.page-background .error-page h1 {
    display: flex;
    color: var(--faded-main-green);
    font-size: 160px;
    line-height: 160px;
    text-shadow: 5px 10px 25px rgba(0, 0, 0, 0.5);
    opacity: 0;

}

.page-background .error-page img {
    display: block;
    position: absolute;
    width: 430px;
    transition: 0.5s ease;
    transform: translateY(-70px);
    animation: animateImage 1000ms ease-in-out;
}

@keyframes animateImage {
    from {
        opacity: 0;
        transform: translateY(-120px);
    }

    to {
        opacity: 1;
        transform: translateY(-70px);
    }
}

.page-background .error-page h2 {
    font-size: 32px;
    z-index: 2;
}

.page-background .error-page h4 {
    font-weight: 300;
    margin: 10px 0 20px;
    display: block;

}

.page-background .error-page .links {
    display: flex;
    flex-direction: row;
}

.page-background .error-page .links a {
    display: inline-block;
    text-decoration: none;
    padding: 5px 20px;
    background: var(--faded-main-green);
    color: #fff;
    border-radius: 3px;

}

.page-background .error-page .links .spacer {
    display: inline-block;
    width: 15px;

}

@media screen and (max-width:525px) {
    .page-background .error-page img {
        width: 400px;
    }

    .page-background .error-page h2 {
        font-size: 24px;
    }
}

@media screen and (max-width:350px) {
    .page-background .error-page img {
        width: 300px;
        transform: translateY(-50px);
    }

    .page-background .error-page h2 {
        font-size: 18px;
    }

    .page-background .error-page h4 {
        font-weight: 300;
        font-size: 10px;
        margin: 0 20px 20px;
        text-align: center;

    }
}