* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

.cont-ainer {
    position: relative;
    width: 100%;
}

.navigation {
    position: fixed;
    width: 300px;
    height: 100%;
    background: var(--main-green);
    transition: 0.5s;
    overflow-x: hidden;
    overflow-y: auto;
    letter-spacing: 1px;
}

.navigation.active {
    width: 60px;
}

.header .header-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: rotateY(-360deg) rotateX(-360deg);
    transition: 0.7s ease;
    transition-delay: 1000ms;
    margin-left: 5px;
}

.header .header-details img {
    position: relative;
    display: block;
    width: 45px;
    height: 45px;
    margin-left: 2px;
}

.header .header-details p {
    position: relative;
    display: block;
    font-weight: 500;
    margin-left: 10px;
}

.header .header-details p > span {
    position: relative;
    display: block;
    font-weight: 700;
    font-size: 1.4em;
}

.navigation.active .header .header-details p {
    display: none;
}

@media screen and (max-width: 480px) {
    .navigation.active .header .header-details p {
        display: block;
    }
}

.navigation ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

}

.navigation ul li:first-child a {
    text-transform: uppercase;
    text-shadow: 1px 1px 1px black;
    line-height: normal;
}

.navigation ul li:hover,
.navigation ul li.nav-active {
    background: var(--golden-rod);
    border-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.navigation ul li:first-child,
.navigation ul li:last-child {
    margin: 15px 0;
}

.navigation ul li:first-child:hover {
    background: transparent;
}

.navigation ul li a {
    position: relative;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: #fff;
    list-style: none;
    line-height: 30px;
    font-size: 11px;

    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    letter-spacing: 1.2px;

}

.navigation ul li a > i {
    position: relative;
    display: block;
    min-width: 50px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    margin-right: 5px;
}

.navigation .title, .sub-group {
    position: relative;
    display: block;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    font-size: 1.1em;
}

.sub-group .item-title {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;
    color: navajowhite;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    text-transform: uppercase;

}

.sub-group .item-title > span {
    font-size: 14px;
}

.sub-group .item-title li {
    transition: 0.5s ease;
}

.sub-group .item-title.active li {
    height: 0;
}

.sub-group .item-title > i {
    transition: 0.5s ease;
    font-size: 1.5em;
}

.sub-group .item-title.active > i {
    transform: rotate(540deg);
}

.sub-group .item-title > span > i {
    margin-right: 15px;
}

@media screen and (max-width: 480px) {
    .navigation {
        width: 0;
        left: 0;
        z-index: 1000;
    }

    .main {
        width: 100%!important;
        left: 0!important;
    }

    .navigation.active {
        width: 100%;
        left: 0;
    }

    .toggle.active {
        z-index: 10000;
        left: 85%;
        transition-delay: 0.7s;
    }

    .toggle.active::before {
        filter: invert();

    }

    .main.active {
        left: 100%;
        width: 100%;
    }
}

.main {
    position: absolute;
    width: calc(100% - 300px);
    left: 300px;
    min-height: 100vh;
    background: #f5f5f5;
    transition: 0.5s;
}

.main.active {
    left: 50px;
    width: calc(100% - 50px);
}

.main .topbar {
    width: 100%;
    background: #fff;
    height: 60px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggle {
    position: relative;
    width: 60px;
    height: 60px;
    background: transparent;
    color: var(--normal-text);
    cursor: pointer;
    display: flex;
    align-items:center;
    justify-content: center;
    transition: 0.5s ease;
    transition-delay: 0.5s;
}

.toggle::before {
    content: '\efa2';
    position: absolute;
    font-family: 'Icofont';
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    text-align: center;

}

.search {
    position: relative;
    width: 400px;
    margin: 0 10px;
}

.search label {
    position: relative;
    width: 100%;
}

.search label input[type=text] {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    padding: 5px 20px;
    outline: none;
    border: 1px solid rgba(0,0,0,20%);
    padding-left: 40px;

}

.search label i {
    position: absolute;
    left: 15px;
    top: 0;
    color: var(--main-green);
    font-weight: 600;
    font-size: 1.2em;
}

.main .user {
    display: block;
}

.main .user .user-details {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 50px;
    margin-right: 10px;
    cursor: pointer;
}

.main .user .user-details > .logged-in-user {
    position: relative;
    font-weight: 600;
}


.main .user .user-details > i {
    position: relative;
    display: none;
}

@media screen and (max-width: 480px) {
    .main .user .user-details > .logged-in-user {
        display: none!important;
    }

    .main .user .user-details > i {
        display: inline-block;
    }
}

.main .user .user-details .details {
    position: absolute;
    z-index: 1000;
    bottom: 10px;
    left: 0;
    width: 200px;
    height: auto;
    background: silver;
    box-shadow: 3px 5px 10px rgba(0,0,0,0.3);
    font-weight: 600;
}

.user .user-details .user-image {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    border: 2px solid var(--lighter-text);
    border-radius: 50%;
    margin-right: 8px;
}

.user .user-details .user-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}


/*==============================================
    USER PROFILE SETTINGS
===============================================*/
.user-details .menu {
    position: absolute;
    top:50px;
    right: 0;
    padding: 10px 20px;
    background: linear-gradient(#fff, whitesmoke);
    width: 200px;
    box-shadow: 0 5px 25px rgba(0,0,0,0.5);
    border-radius: 15px;
    transition: 0.5s;
    z-index: 10002;
    visibility: hidden;
    opacity: 0;
}

.user-details.active .menu {
    visibility: visible;
    opacity: 1;
}

.user-details .menu::before {
    position: absolute;
    content: '';
    top: 5px;
    left: 50%;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg) translateX(-50%);
}

.user-details .menu h3 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding: 15px 0;
    font-weight: 600;
    color: var(--main-green);
    line-height: 1.2em;

}

.user-details .menu h3 span {

    position: relative;
    font-size: 10px;
    font-weight: 600;
    padding: 2px 5px;
    text-transform: uppercase;
    border: 1px solid var(--lighter-text);
    background: linear-gradient(gainsboro, whitesmoke);
    color: #000;
}

.user-details .menu ul li {
    list-style: none;
    padding: 10px 0;
    border-top: 1px solid rgba(0,0,0,0.05);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.user-details .menu ul li:hover {
    background: whitesmoke;
}

.user-details .menu ul li > i {
    font-size: 20px;
    margin-right: 10px;
    opacity: 0.5;
    background: rgba(29, 112, 29, 0.2);
    padding: 5px;
    border-radius: 50%;
    transition: opacity 0.5s;

}

.user-details .menu ul li:hover > i {
    opacity: 1;
}

.user-details .menu ul li > a {
    position: relative;
    display: inline-block;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    color:rgba(29, 112, 29, 0.8)
}

.user-details .menu ul li:hover > a {
    font-weight: 600;
    text-decoration: underline;
    color: var(--faded-main-green);
}



/* Cards */

.main .cardBox {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

@media screen and (max-width: 947px) {
    .main .cardBox {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 545px) {
    .main .cardBox {
        grid-template-columns: repeat(1, 1fr);
    }
}

.main .cardBox .s-card {
    background: #fff;
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 3px 5px 15px rgba(0,0,0,0.3);
}

.cardBox .s-card .numbers {
    font-size: 2.5em;
    font-weight: 600;
    position: relative;
    color: var(--golden-rod);
}

.cardBox .s-card .cardName {
    color: var(--faded-text);
}

.cardBox .s-card .iconBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.cardBox .s-card .iconBox i {
    display: block;
    font-size: 2.5em;
    width: 40px;
    height: 40px;
    color: var(--main-green);
    background: rgba(29, 112, 29, 0.2);
    padding: 5px;
    border-radius: 50%;
    text-align: center;

}

.cardBox .s-card .iconBox span {
    display: block;
    font-size: 0.7em;
    color: var(--faded-text);
    text-align: right;
    margin-top: 5px;
}

.control-label {
    color: #626264;
    font-size: 0.9em;
    font-weight: bold;
}

#searchValue {
    background: var(--main-color);
    color: navajowhite;
    width: 60px;
    text-align: center;
}

.selCategory {
    background: var(--main-color);
    color: navajowhite;
    padding: 2px 12px;
    margin-left: 10px;
    font-size: 1.2em;
    border-radius: 2px;
}

.bg-medlight {
    background: #ededed;
}

/* Page title */
.page-title {
    display: block;
    position: relative;
    width: 100%;
    padding: 20px;
    padding-bottom: 0;
    color: var(--main-green);

}

.page-title > h2 {
    font-family: 'M-Bold';
    text-transform: uppercase;
    font-weight: 600;
}

.page-title > h2 > i {
    position: relative;
    display: inline-block;
    color: var(--main-green);
    background: rgba(29, 112, 29, 0.2);
    border-radius: 50%;
    padding: 7px;
    text-align: center;
    font-size: 1.2em;
    margin-right: 7px;
}

/* SUMMARIES */
.main-summaries, .branch-summaries {
    display: grid;
    position: relative;
    margin-bottom: 30px;
    grid-template-columns: 2fr 1fr;
    min-height: 300px;
    gap: 15px;
    padding: 20px;
}

.branch-summaries {
    grid-template-columns: 1fr;
}

@media screen and (max-width: 947px) {
    .main-summaries {
        grid-template-columns: 1fr;
    }
}

.main-summaries > .summaries, .main-summaries .audits,
.branch-summaries > .summaries {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #fff;
    padding: 30px 20px;
    box-shadow: 3px 3px 15px rgba(60,60,60,0.2);
    border-radius: 4px;
    min-height: 74vh;
}

@media screen and (max-width: 640px) {
    .main-summaries > .summaries {
        overflow-x: auto;

    }
}

.main-summaries > .summaries h3, .audits h3,
.branch-summaries > .summaries h3 {
    font-size: 1.4em;
    font-weight: 600;
    color: var(--main-green);
    margin-bottom: 10px!important;
}

.main-summaries .summaries .title .buttons,
.branch-summaries .summaries .title .buttons {
    display: inline-block;
}

.main-summaries > .summaries .title > button,
.branch-summaries > .summaries .title > button {
    display: inline-block;
    background: var(--main-green);
    outline: none;
    border: none;
    padding: 5px 15px;
    color: #fff;
    font-weight: 400;
}

.table-container {
    display: block;
    width: 100%;
    overflow-x: auto;
}

.custom-table-head {
    background: linear-gradient(white, whitesmoke, gainsboro);
}

table {
    width: 100%;
    display: table;
    border-collapse: collapse;
    letter-spacing: 0.6px;
}

table > thead > tr {
    border-bottom: 1px solid #bbb;
     color: var(--main-green);
    background: #fff;
    cursor: default;
    font-weight: 600;
}

table > tbody > tr {
    border-bottom: 1px solid #ccc;
}

table > tbody > tr:hover {
    color: #000;
    /* background: #c7c7c7; */
    background: var(--transparent-green);
}

table > thead > tr > th {
    font-size: 14px;
    font-weight: 600;
}

table tbody tr:last-child {
    border-bottom: none;
}

table td, table th {
    padding: 8px 5px;
}

table td > .user-pix {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
}

table td > .user-pix > img{
    position: relative;
    display: block;
    width: 40px;
    border: 2px solid var(--faded-text);
    border-radius: 50%;
    margin-right: 5px;
}

table td > .user-pix .user-data > p {
    position: relative;
    font-size: 1em;
    color: var(--main-green);
    font-weight: 600;
    margin-bottom: 3px;

}

table td > .user-pix .user-data > span {
    position: relative;
    font-size: 0.8em;
    font-weight: 600;
    padding: 2px 5px;
    text-transform: uppercase;
    border: 1px solid var(--lighter-text);
    background: linear-gradient(gainsboro, whitesmoke);
    color: #000;

}

.align-right {
    position: relative;
    text-align: right!important;
}

.align-left {
    position: relative;
    text-align: left!important;
}

.align-center {
    position: relative;
    text-align: center!important;
}

.audits .cont .audit-title > i {
    font-size: 1.4em;
    color: #fff;
    background: var(--main-green);
    padding: 8px;
    border-radius: 4px;

}

.audits .cont {
    display: flex;
    flex-direction: column;
}

.audits .cont .audit-title, .main-summaries .summaries .title,
.branch-summaries .summaries .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

#audit-table tbody tr td:last-child {
    position: relative;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
    color: #666;
}

#audit-table tbody tr td:last-child > .online-status {
    display: block;
    position: relative;
    background:rgba(29, 112, 29, 0.8);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;

}

#audit-table tbody tr td:last-child > .online-status.offline {
    background: var(--reddish);
}